import { StyleSheet } from 'aphrodite'
import './src/components/layouts/layout.css'
import './node_modules/inno-components/build/index.css'
import './src/styles/lib/grid.css'

import './node_modules/video-react/dist/video-react.css'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onClientEntry = () => {
  if (window.gatsbyAphrodite) {
    StyleSheet.rehydrate(window.gatsbyAphrodite)
  }
}
